<template>
  <div>
    <div class="flex-row flex-as">
      <div class="computed-title">销售额排名</div>
      <div>
        <el-table
          border
          :data="saleRankTableData"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="600px"
          style="width: auto; flex: 0 1 auto"
          class="overflow-y"
          v-loading="loading"
        >
          <el-table-column label="" width="50">
            <template slot-scope="scope">
              {{ saleRankIndex(scope.$index) }}
            </template>
          </el-table-column>
          <el-table-column label="剧本名称" width="250">
            <template slot-scope="slot">
              {{ slot.row.scenarioName }}
            </template>
          </el-table-column>
          <el-table-column label="剧本人数" width="120">
            <template slot-scope="slot"> {{ slot.row.playNum }}人本 </template>
          </el-table-column>
          <el-table-column label="剧本销售额" width="120">
            <template slot-scope="slot">
              {{ slot.row.salesPrice }}点券
            </template>
          </el-table-column>
          <el-table-column label="整合完整销量" width="120">
            <template slot-scope="slot">
              {{ slot.row.completeSalesQuantity }}本
            </template>
          </el-table-column>
          <el-table-column label="剧本销量" width="120">
            <template slot-scope="slot">
              {{ slot.row.salesQuantity }}本
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="saleRankCount"
          :page-size="saleRankTableFilter.limit"
          :current-page="saleRankTableFilter.currPage"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleRankSizeChange"
          @current-change="handleRankCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    saleRankTableData: {
      type: Array,
      deafult: () => [],
    },
    saleRankCount: Number,
    loading: Boolean,
    saleRankTableFilter: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    handleRankSizeChange(size) {
      this.$emit("handleRankSizeChange", size);
    },
    handleRankCurrentChange(page) {
      this.$emit("handleRankCurrentChange", page);
    },
    saleRankIndex(index) {
      return (
        (this.saleRankTableFilter.currPage - 1) *
          this.saleRankTableFilter.limit +
        index +
        1
      );
    },
  },
};
</script>