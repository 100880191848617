import { render, staticRenderFns } from "./scenarioGameRecordTable.vue?vue&type=template&id=731e4a62&scoped=true"
import script from "./scenarioGameRecordTable.vue?vue&type=script&lang=js"
export * from "./scenarioGameRecordTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "731e4a62",
  null
  
)

export default component.exports