<template>
  <el-table
    border
    :data="list"
    :header-cell-style="thStyle"
    :cell-style="cellStyle"
    style="width: auto; flex: 0 1 auto"
    v-loading="loading"
  >
    <el-table-column label="序号" width="65">
      <template slot-scope="scope">
        {{ gameRankIndex(scope.$index) }}
      </template>
    </el-table-column>
    <el-table-column label="剧本名称" prop="scenarioName" width="150">
    </el-table-column>
    <el-table-column  label="用户昵称" width="166">
      <template slot-scope="scope">
        <div
          class="m-b-5"
          v-for="(item, index) in scope.row.username"
          :key="index"
        >
          {{ item }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="扮演角色" width="120">
      <template slot-scope="scope">
        <div
          class="m-b-5"
          v-for="(item, index) in scope.row.roleName"
          :key="index"
        >
          {{ item }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="状态" width="100">
      <template slot-scope="scope">
        <div
          class="m-b-5"
          :style="{ color: checkColor(item) ? '#000' : '#F394AC' }"
          v-for="(item, index) in scope.row.status"
          :key="index"
        >
        
        <question-tooltip :text="detailStatus[item].text" :disabled="!detailStatus[item].text" type="text" :content="detailStatus[item].label" />
        <!-- <el-popover placement="top-start" trigger="hover">
          <span>{{detailStatus[item].text}}</span>
          <div slot="reference" class="name-wrapper">
            {{ detailStatus[item].label }}
          </div>
        </el-popover> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column label="开始时间" width="180">
      <template slot-scope="scope">
        <div
          class="m-b-5"
          v-for="(item, index) in scope.row.startTime"
          :key="index"
        >
          {{ item }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="开局时长" width="100">
      <template slot-scope="scope">
        <div
          class="m-b-5"
          v-for="(item, index) in scope.row.roundTimeLength"
          :key="index"
        >
          {{ item }}分钟
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import QuestionTooltip from "../../../../components/common/questionTooltip.vue";
export default {
  components: {
    QuestionTooltip
  },
  props: {
    detailTableData: {
      type: Array,
      deafult: () => [],
    },
    loading: Boolean,
    detailTableFilter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      detailStatus: {
        0: { label: "未结束"},
        1: { label: "正常退出" },
        2: { label: "中途退出" },
        3: { label: "中途退出" },
        11: { label: "中途退出①", text: '剧本有剧本内容收费项，用户在未到达剧本内容商品的付费时机前退出。' },
        12: { label: "中途退出②", text: '剧本有剧本内容收费项，用户在到达剧本内容商品的付费时机后，到达“剧终投票”前，且没有购买剧本内容，退出。' },
        13: { label: "正常退出③", text: '剧本有剧本内容收费项，用户到达“剧终投票”后，且没有购买剧本内容，退出。' },
        14: { label: "中途退出④", text: '剧本有剧本内容收费项，用户在到达剧本内容商品的付费时机后，到达“剧终投票”前，且购买了剧本内容后，退出。' },
        15: { label: "正常退出⑤", text: '剧本有剧本内容收费项，用户到达“剧终投票”后，且购买了剧本内容后，退出。' }
      },
    };
  },
  methods: {
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    gameRankIndex(index) {
      return (
        (this.detailTableFilter.currPage - 1) * this.detailTableFilter.limit +
        index +
        1
      );
    },
    checkColor(item) {
      return item < 2 || item === 13 || item === 15
    }
  },
  computed: {
    list() {
      return this.detailTableData.map(
        ({ scenarioName, userGameDetailList }) => {
          return {
            scenarioName,
            username: userGameDetailList.map(({ username }) => username),
            roleName: userGameDetailList.map(({ roleName }) => roleName),
            status: userGameDetailList.map(({ status }) => status),
            startTime: userGameDetailList.map(({ startTime }) => startTime),
            roundTimeLength: userGameDetailList.map(
              ({ roundTimeLength }) => roundTimeLength
            ),
          };
        }
      );
    },
  },
};
</script>

<style lang="sass" scoped>
</style>