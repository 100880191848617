<template>
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="(item, index) in tabList"
        :key="`a${index}`"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <div>
      <!-- <el-select
        class="select-item"
        style="width: 100px"
        size="mini"
        placeholder="排序"
      >
      </el-select> -->
      <div class="m-b-10" v-show="curTab === 1">
        <filter-item @dealFilter="dealFilter" :showScenario="true" />
      </div>
      <filter-item v-show="curTab === 2" @dealFilter="dealFilter" />
      <filter-item v-show="curTab === 3" @dealFilter="dealFilter" />
      <filter-item
        v-show="curTab === 4"
        @dealFilter="dealFilter"
        :showScenario="true"
        :showStatus="true"
      />
    </div>
    <div class="content-wrap">
      <el-card class="row2-card" v-loading="loading">
        <div class="flex-row flex-as">
          <div class="title">数据表</div>
          <div class="table-remark" v-html="remarkText"></div>
        </div>
        <game-record-table
          v-if="curTab === 1"
          :gameRecordTableData="gameRecordTableData"
          :loading="loading"
        />
        <game-rank-table
          v-if="curTab === 2"
          :gameRankTableData="gameRankTableData"
          :loading="loading"
          :gameRankCount="gameRankCount"
          :gameRankTableFilter="gameRankTableFilter"
          @handleRankSizeChange="handleRankSizeChange"
          @handleRankCurrentChange="handleRankCurrentChange"
        />
        <sale-rank-table
          v-if="curTab === 3"
          :saleRankTableData="saleRankTableData"
          :loading="loading"
          :saleRankCount="saleRankCount"
          :saleRankTableFilter="saleRankTableFilter"
          @handleRankSizeChange="handleRankSizeChange"
          @handleRankCurrentChange="handleRankCurrentChange"
        />
        <detail-table
          v-if="curTab === 4"
          :detailTableData="detailList"
          :loading="loading"
          :detailTableFilter="detailTableFilter"
        />
        <div v-show="curTab !== 4" class="flex-row flex-as">
          <div class="title">数据图</div>
          <div class="flex-column">
            <el-checkbox-group
              v-if="curTab === 1"
              v-model="gameRecordCheckList"
              style="width: 750px"
            >
              <div v-for="(item1, index1) in recordCheckItem" :key="index1">
                <el-checkbox
                  v-for="(item2, index2) in gameRecordNameList.slice(
                    item1.start,
                    item1.end
                  )"
                  :key="item2"
                  style="width: 150px"
                  :label="index2 + item1.start + 1 + ''"
                  :disabled="gameRecordIsPieType"
                  >{{ item2 }}</el-checkbox
                >
              </div>
            </el-checkbox-group>
            <el-button
              v-if="curTab === 1"
              class="m-t-10 m-b-10"
              type="primary"
              size="mini"
              @click="showChart"
              :disabled="gameRecordIsPieType"
              >生成/刷新数据图</el-button
            >
            <echart-item
              v-loading="loading"
              v-show="curTab === 1"
              :data="gameRecordChartData"
              :lineList="gameRecordLineList"
              :nameList="gameRecordNameList"
              :pieData1="gameRecordPieData1"
              :pieData2="gameRecordPieData2"
              :pieData3="gameRecordPieData3"
              :pieData4="gameRecordPieData4"
              pieDataName1="游戏人流（单位：人或次）"
              :pieDataName2="`性别占比（单位：人）`"
              :pieDataName3="`年龄占比（单位：人）`"
              :pieDataName4="`游戏行为（单位：分钟）`"
              @pieType="pieType"
              dataType="gameRocord"
            />
            <echart-item
              v-loading="loading"
              v-show="curTab === 2"
              :data="gameRankChartData"
              :nameList="gameRankNameList"
              :lineList="gameRankLineList"
              dataType="gameRank"
              :noPie="true"
            />
            <echart-item
              v-loading="loading"
              v-show="curTab === 3"
              :data="saleRankChartData"
              :nameList="saleRankNameList"
              :lineList="saleRankLineList"
              dataType="saleRank"
              :noPie="true"
            />
          </div>
        </div>
      </el-card>
    </div>
    <div class="row3">
      <el-pagination
        v-if="curTab === 4"
        @size-change="handleDetailSizeChange"
        @current-change="handleDetailCurrentChange"
        :current-page.sync="detailTableFilter.currPage"
        :page-size="detailTableFilter.limit"
        :total="detailCount"
        :page-sizes="[10, 15, 20, 25]"
        layout="sizes, prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import FilterItem from "./common/component/FilterItem.vue";
import EchartItem from "./common/component/EchartItem.vue";
import GameRecordTable from "./common/component/scenarioGameRecordTable";
import GameRankTable from "./common/component/scenarioGameRankTable";
import SaleRankTable from "./common/component/scenarioSaleRankTable";
import DetailTable from "./common/component/scenarioDetailTable";
import { timestampToDate, debounce, deepCopy } from "@tools";
import { mapState } from "vuex";
export default {
  components: {
    FilterItem,
    EchartItem,
    GameRecordTable,
    GameRankTable,
    SaleRankTable,
    DetailTable,
  },
  data() {
    this.recordCheckItem = [
      { start: 0, end: 3 },
      { start: 3, end: 6 },
      { start: 6, end: 9 },
      { start: 9, end: 12 },
      { start: 12, end: 16 },
      { start: 16, end: 20 },
      { start: 20, end: 25 },
      { start: 25, end: 27 },
      { start: 27, end: 30 },
      { start: 30, end: 32 },
      { start: 32, end: 35 },
      { start: 35, end: 37 },
      { start: 37, end: 42 },
    ];
    return {
      tabList: [
        { name: "游戏记录", id: 1 },
        { name: "游戏排名", id: 2 },
        { name: "销售排名", id: 3 },
        { name: "详细记录", id: 4 },
      ],
      gameRecordCheckList: ["1"], // 游戏记录的选项组
      gameRankCheckList: ["1"], // 游戏排名的选项组
      saleRankCheckList: ["1"], // 销售排名的选项组
      curTab: 1,
      gameRecordTableData: {}, // 游戏记录的所有表格数据
      gameRankTableData: [], // 游戏排名的所有表格数据
      saleRankTableData: [], // 销售排名的所有表格数据
      loading: false,
      gameRecordTableFilter: {
        dataType: 1,
        day: 30,
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
        scenarioId: "",
      }, // 游戏记录的顶部筛选条件
      gameRankTableFilter: {
        day: 30,
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
        limit: 10,
        currPage: 1,
      }, // 游戏排名的顶部筛选条件
      saleRankTableFilter: {
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
        limit: 10,
        currPage: 1,
      }, // 销售排名的顶部筛选条件
      gameRecordNameList: [
        "总游戏用户数",
        "免费本游戏用户数",
        "外购本游戏用户数",
        "总内购游戏用户数",
        "免费本内购游戏用户数",
        "外购本内购游戏用户数",
        "总游戏人次",
        "免费本游戏人次",
        "外购本游戏人次",
        "总内购游戏人次",
        "免费本内购游戏人次",
        "外购本内购游戏人次",
        "1局用户数",
        "2局用户数",
        "≥3局用户数",
        "无效用户数",
        "游戏局数",
        "总中途退出用户数",
        "语音耗时",
        "游戏时长",
        "退出用户数①",
        "退出用户数②",
        "退出用户数③",
        "退出用户数④",
        "退出用户数⑤",
        "总销售额",
        "外购销售额",
        "总内购销售额",
        "内容销售额",
        "线索销售额",
        "总客单价",
        "外购客单价",
        "总内购客单价",
        "内容客单价",
        "线索客单价",
        "男性",
        "女性",
        "≤17岁",
        "18-24岁",
        "25-29岁",
        "30-39岁",
        "≥40岁",
      ], // 游戏记录的选项名称数组
      gameRankNameList: ["游戏局数", "游戏人次", "游戏用户数", "剧本评分"], // 游戏排名的选项名称数组
      saleRankNameList: ["剧本销售额", "真个好完整销量", "剧本销量"], // 销售记录的选项名称数组
      gameRecordChartData: [], // 游戏记录图
      gameRankChartData: [], // 游戏排名图
      saleRankChartData: [], // 销售排名图
      gameRecordPieData1: [], // 游戏记录饼图数据
      gameRecordPieData2: [], // 游戏记录饼图数据
      gameRecordPieData3: [], // 游戏记录饼图数据
      gameRecordPieData4: [], // 游戏记录饼图数据
      gameRecordLineList: [
        "总游戏人次",
        "总游戏用户数",
        "1局用户数",
        "2局用户数",
        "≥3局用户数",
        "游戏局数",
        "语音耗时",
        "游戏时长",
        "总中途退出用户",
      ], // 游戏记录需要折线的选项
      gameRankLineList: ["游戏局数", "游戏人次", "游戏用户数", "剧本评分"], // 需要折线的选项
      saleRankLineList: ["剧本销售额", "整合完整销量", "剧本销量"], // 需要折线的选项
      gameRecordIsPieType: false,
      gameRecordPieCheckList: [
        "1",
        "13",
        "3",
        "14",
        "15",
        "19",
        "20",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
      ], // 饼状图需要勾选死的选项
      gameRankListLimit: 10, // 点券排名表格的limit
      gameRankCount: 0, // 点券排名表格的limit
      gameRankSize: 10,
      gameRankPage: 1,
      saleRankCount: 0,
      detailTableFilter: {
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
        limit: 10,
        currPage: 1,
        scenarioId: "",
        status: "",
      },
      detailCount: 0,
      detailList: [],
    };
  },
  async created() {
    this.getScenrioDataList();
  },
  methods: {
    getScenrioDataList: debounce(async function (page = null) {
      this.loading = true;
      const params = this[`${this.filterName}TableFilter`];
      delete params.dataFlagList;
      if (this.curTab !== 1 && page) {
        params.currPage = 1;
      }
      params.ignoreMinute = localStorage.ignoreMinute;
      const data = await this.$api.chiguaData[`get${this.apiName}DataList`](
        params
      ).finally(() => {
        this.loading = false
      });
      this.loading = true
      // 处理数据
      if (this.curTab === 1) {
        const data1 = await this.$api.chiguaData[`get${this.apiName}DataList`](
          {...params, dataType: 2}
        );
        const data2 = await this.$api.chiguaData[`get${this.apiName}DataList`](
          {...params, dataType: 3}
        );
        const data3 = await this.$api.chiguaData[`get${this.apiName}DataList`](
          {...params, dataType: 4}
        );
        Object.assign(data, {
          gameBehaviors: data1.gameBehaviors,
          innerBuyBehaviors: data2.innerBuyBehaviors,
          payTrends: data3.payTrends,
        });
        // console.log(data);
        let list1 = [],
          list2 = [],
          list3 = [],
          list4 = [],
          list5 = [],
          list6 = [],
          obj1 = { title: "总数" },
          obj2 = { title: "日平均值" },
          obj3 = { title: "占比" },
          obj4 = {};
        data.userFlows.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.quantity + "人";
          obj2[`num${key}`] = item.value + "人";
          obj3[`num${key}`] = item.ratio;
        });
        list1.push(obj1, obj2, obj3);
        obj1 = { title: "总数" };
        obj2 = { title: "日平均值" };
        obj3 = { title: "局平均数" };
        data.gameBehaviors.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.quantity;
          obj2[`num${key}`] = item.value !== undefined ? item.value : "-";
          obj3[`num${key}`] = item.avg !== 0 ? item.avg : "-";
        });
        list2.push(obj1, obj2, obj3);
        obj1 = { title: "总数", unit: "人" };
        obj2 = { title: "占比", unit: "%" };
        data.genderPercentages.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.quantity;
          obj2[`num${key}`] = item.value;
        });
        list3.push(obj1, obj2);
        obj1 = { title: "总数", unit: "人" };
        obj2 = { title: "占比", unit: "%" };
        data.agePercentages.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.quantity;
          obj2[`num${key}`] = item.value;
        });
        list4.push(obj1, obj2);
        obj1 = { title: "总数" };
        obj2 = { title: "日平均值" };
        obj3 = { title: "占比" };
        data.payTrends.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.quantity + "点券";
          obj2[`num${key}`] = item.value + "点券";
          obj3[`num${key}`] = item.ratio;
        });
        list5.push(obj1, obj2, obj3);
        obj1 = { title: "总数" };
        obj2 = { title: "日平均值" };
        obj3 = { title: "局平均值" };
        obj4 = { title: "占比" };
        data.innerBuyBehaviors.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.quantity + "人";
          obj2[`num${key}`] = item.value + "人";
          obj3[`num${key}`] = item.avg + "人";
          obj4[`num${key}`] = item.ratio;
        });
        list6.push(obj1, obj2, obj3, obj4);
        this.gameRecordTableData = {
          list1,
          list2,
          list3,
          list4,
          list5,
          list6,
        };
        console.log(this.gameRecordTableData);
      } else if (this.curTab === 2) {
        this.gameRankCount = data.count;
        this.gameRankTableData = data.data;
      } else if (this.curTab === 3) {
        this.saleRankCount = data.count;
        this.saleRankTableData = data.data;
      } else if (this.curTab === 4) {
        this.detailList = data.data;
        this.detailCount = data.count;
      }
      this.loading = false;
      this.curTab === 1 && this.getPieData(this.gameRecordTableData); // 本页面只有游戏记录有饼状图
      this.curTab !== 4 && this.getScenrioDataChart(); // 本页面只有游戏记录需要获取chart数据
    }, 300),
    async getScenrioDataChart() {
      this.loading = true;
      let checkList =
          this.gameRecordIsPieType && this.curTab === 1 // 防止饼图状态时，筛选，然后影响到后面切其他图
            ? "PieCheckList"
            : "CheckList",
        params = this[`${this.filterName}TableFilter`];
      params.dataFlagList = this[`${this.filterName}${checkList}`]
        .sort((a, b) => a - b)
        .toString();
      if (this.curTab === 1) {
        params.ignoreMinute = localStorage.ignoreMinute;
        const data = await this.$api.chiguaData[`get${this.apiName}DataChart`](
          params
        ).finally(() => {
          this.loading = false;
        });
        this.gameRecordChartData = data;
      } else if (this.curTab === 2 || this.curTab === 3) {
        this.loading = false;
        const list = deepCopy(this[`${this.filterName}TableData`]);
        list.reverse();
        const dataArr1 =
          this.curTab === 2
            ? [
                {
                  name: "游戏局数",
                  type: "bar",
                  data: list.map((item) => item.gameRoundQuantity),
                },
                {
                  name: "游戏人次",
                  type: "bar",
                  data: list.map((item) => item.gamePeopleQuantity),
                },
                {
                  name: "游戏用户数",
                  type: "bar",
                  data: list.map((item) => item.gameUserQuantity),
                },
                {
                  name: "剧本评分",
                  type: "bar",
                  data: list.map((item) => item.scenarioComment),
                },
              ]
            : [
                {
                  name: "剧本销售额",
                  type: "bar",
                  data: list.map((item) => item.salesPrice),
                },
                {
                  name: "整合完整销量",
                  type: "bar",
                  data: list.map((item) => item.completeSalesQuantity),
                },
                {
                  name: "剧本销量",
                  type: "bar",
                  data: list.map((item) => item.salesQuantity),
                },
              ];

        this[`${this.filterName}ChartData`] = {
          dateArr: list.map(
            (item) =>
              `${item.freeFlag || item.freeFlag === undefined ? "" : "*"}${
                item.scenarioName
              }(${item.playNum}人本)`
          ),
          dataArr1,
          dataArr2: [],
        };
      }
    },
    dealFilter(params, day) {
      this.curTab === 1 && (params.day = day);
      // this.curTab === 2 && (params.limit = this.countListLimit);
      // this[`${this.filterName}TableFilter`] = params;
      Object.assign(this[`${this.filterName}TableFilter`], params);
      this.getScenrioDataList(1);
    },
    showChart() {
      this.getScenrioDataChart();
    },
    getPieData(data) {
      const { list1, list2, list3, list4 } = data;
      // 处理饼图的数据
      this.gameRecordPieData1.splice(
        0,
        5,
        { name: "总游戏用户数", value: parseInt(list1[0].num1) },
        { name: "1局用户数", value: parseInt(list1[0].num13) },
        { name: "2局用户数", value: parseInt(list1[0].num14) },
        { name: "≥3局用户数", value: parseInt(list1[0].num15) }
      );
      this.gameRecordPieData2.splice(
        0,
        2,
        { name: "帅哥", value: list3[0].num1 },
        { name: "美女", value: list3[0].num2 }
      );
      this.gameRecordPieData3.splice(
        0,
        5,
        { name: "≤17岁", value: list4[0].num1 },
        { name: "18-24岁", value: list4[0].num2 },
        { name: "25-29岁", value: list4[0].num3 },
        { name: "30-39岁", value: list4[0].num4 },
        { name: "≥40岁", value: list4[0].num5 }
      );
      this.gameRecordPieData4.splice(
        0,
        2,
        { name: "语音耗时", value: list2[0].num3 },
        { name: "游戏时长", value: list2[0].num4 }
      );
    },
    pieType(isPie) {
      // 切换到饼图时的回调，变灰
      const isPieType = this.gameRecordIsPieType;
      if ((!isPieType && isPie) || (isPieType && !isPie)) {
        [this.gameRecordCheckList, this.gameRecordPieCheckList] = [
          this.gameRecordPieCheckList,
          this.gameRecordCheckList,
        ];
      }
      this.gameRecordIsPieType = isPie;
    },
    changeCountListLimit() {
      this.tableFilter2.limit =
        Number(this.countListLimit) < 5 ? 5 : this.countListLimit;
      this.getBusinessDataList(false);
    },
    handleRankSizeChange(size) {
      this[`${this.filterName}TableFilter`].limit = size;
      this.getScenrioDataList();
    },
    handleRankCurrentChange(page) {
      this[`${this.filterName}TableFilter`].currPage = page;
      this.getScenrioDataList();
    },
    handleDetailSizeChange(size) {
      this.detailTableFilter.limit = size;
      this.getScenrioDataList();
    },
    handleDetailCurrentChange(page) {
      this.detailTableFilter.currPage = page;
      this.getScenrioDataList();
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {
    curTab() {
      this.getScenrioDataList();
    },
    gameRecordCheckList: {
      deep: true,
      handler(val) {
        if (val.length > 10 && !this.gameRecordIsPieType) {
          this.$message.warning("由于数据量巨大，只能一次性选十个哦！");
          this.gameRecordCheckList.pop();
        }
      },
    },
  },
  computed: {
    filterName() {
      return ["gameRecord", "gameRank", "saleRank", "detail"][this.curTab - 1];
    },
    apiName() {
      return ["GameRecord", "GameRank", "SaleRank", "Detail"][this.curTab - 1];
    },
    remarkText() {
      const tab = this.curTab;
      if (tab === 1) {
        return "注：因微信数据延迟，昨天的部分数据可能未更新；";
        // <br> 2.“整合完整销量”=单一剧本总销售额÷该剧本完整本现价，是把所有单人本凑整为完整本的销量；<br> 3.“流失用户数”指首次开局中途退出游戏后，近两周没有再登录的用户数；<br> 4.“性别占比”、“年龄占比”数据表数据从2021年2月13日开始记录。";
      } else if (tab === 2) {
        return "注：带*号为现时免费剧本。";
      } else if (tab === 3) {
        return "注：“整合完整销量”=单一剧本总销售额÷该剧本完整本现价，是把所有单人本凑整为完整本的销量。";
      }
      return "注：因微信数据延迟，昨天的部分数据可能未更新。";
    },
    ...mapState(["scenarioList"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
.row3 {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}
/* .row2-card {
  overflow: hidden !important;
} */
</style>