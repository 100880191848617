<template>
  <div>
    <div class="flex-row flex-as">
      <div class="computed-title">局数排名</div>
      <div>
        <el-table
          border
          :data="gameRankTableData"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="600px"
          style="width: auto; flex: 0 1 auto"
          class="overflow-y"
          v-loading="loading"
        >
          <el-table-column label="" width="50">
            <template slot-scope="scope">
              {{ gameRankIndex(scope.$index) }}
            </template>
          </el-table-column>
          <el-table-column label="剧本名称" width="200">
            <template slot-scope="slot">
              {{ slot.row.freeFlag ? "" : "*" }}{{ slot.row.scenarioName }}
            </template>
          </el-table-column>
          <el-table-column label="剧本人数" width="100">
            <template slot-scope="slot"> {{ slot.row.playNum }}人本 </template>
          </el-table-column>
          <el-table-column label="游戏局数" width="100">
            <template slot-scope="slot">
              {{ slot.row.gameRoundQuantity }}局
            </template>
          </el-table-column>
          <el-table-column label="游戏人次" width="100">
            <template slot-scope="slot">
              {{ slot.row.gamePeopleQuantity }}次
            </template>
          </el-table-column>
          <el-table-column label="游戏用户数" width="100">
            <template slot-scope="slot">
              {{ slot.row.gameUserQuantity }}人
            </template>
          </el-table-column>
          <el-table-column label="剧本评分" width="100">
            <template slot-scope="slot">
              {{ slot.row.scenarioComment }}分
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="gameRankCount"
          :page-size="gameRankTableFilter.limit"
          :current-page="gameRankTableFilter.currPage"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleRankSizeChange"
          @current-change="handleRankCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gameRankTableData: {
      type: Array,
      deafult: () => [],
    },
    gameRankCount: Number,
    loading: Boolean,
    gameRankTableFilter: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    handleRankSizeChange(size) {
      this.$emit("handleRankSizeChange", size);
    },
    handleRankCurrentChange(page) {
      this.$emit("handleRankCurrentChange", page);
    },
    gameRankIndex(index) {
      return (
        (this.gameRankTableFilter.currPage - 1) *
          this.gameRankTableFilter.limit +
        index +
        1
      );
    },
  },
};
</script>