<template>
  <div>
    <div class="flex-row flex-as">
      <div class="computed-title">游戏人流</div>
      <div class="flex-1 overflow-x max-w-800">
        <el-table
          border
          class="m-b-10"
          :data="gameRecordTableData.list1"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          style="width: auto; flex: 0 1 auto"
        >
          <!-- <el-table-column slot="header"> </el-table-column> -->
          <el-table-column prop="title" label="" width="80"> </el-table-column>
          <el-table-column
            v-for="(item, index) in list1Items1"
            :key="index"
            :label="item.label"
            :render-header="(h, c) => renderHeader(h, c, item.title)"
            :width="item.width"
          >
            <template slot-scope="scope">
              {{ `${scope.row[item.key]}` }}
            </template>
          </el-table-column>
        </el-table>
        <el-table
          class="m-b-10"
          border
          :data="gameRecordTableData.list1"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          style="width: auto; flex: 0 1 auto"
        >
          <!-- <el-table-column slot="header"> </el-table-column> -->
          <el-table-column prop="title" label="" width="80"> </el-table-column>
          <el-table-column
            v-for="(item, index) in list1Items2"
            :key="index"
            :label="item.label"
            :render-header="(h, c) => renderHeader(h, c, item.title)"
            :width="item.width"
          >
            <template slot-scope="scope">
              {{ `${scope.row[item.key]}` }}
            </template>
          </el-table-column>
        </el-table>
        <el-table
          border
          class="m-b-10"
          :data="gameRecordTableData.list1"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          style="width: auto; flex: 0 1 auto"
        >
          <!-- <el-table-column slot="header"> </el-table-column> -->
          <el-table-column prop="title" label="" width="80"> </el-table-column>
          <el-table-column
            v-for="(item, index) in list1Items3"
            :key="index"
            :label="item.label"
            :render-header="(h, c) => renderHeader(h, c, item.title)"
            :width="item.width"
          >
            <template slot-scope="scope">
              {{ `${scope.row[item.key]}` }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="flex-row flex-as">
      <div class="computed-title">游戏行为</div>
      <el-table
        border
        class="m-b-10"
        :data="gameRecordTableData.list2"
        :header-cell-style="thStyle"
        :cell-style="cellStyle"
        style="width: auto; flex: 0 1 auto"
      >
        <el-table-column prop="title" label="" width="80"> </el-table-column>
        <el-table-column
          v-for="(item, index) in list2Items"
          :render-header="(h, c) => renderHeader(h, c, item.title)"
          :key="index"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            {{
              `${
                index === 2 && scope.row[item.key] !== "-"
                  ? Math.ceil(scope.row[item.key] / 60)
                  : scope.row[item.key]
              }${scope.row[item.key] === "-" ? "" : item.unit}`
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-row flex-as">
      <div class="computed-title flex-row flex-ac no-wrap">
        内购行为
        <question-tooltip>
          <div slot="content">
            <img src="../../../../assets/neigou.png" width="476" alt="" />
          </div>
        </question-tooltip>
      </div>
      <el-table
        border
        class="max-w-800 m-b-10"
        :data="gameRecordTableData.list6"
        :header-cell-style="thStyle"
        :cell-style="cellStyle"
        style="width: auto; flex: 0 1 auto"
      >
        <el-table-column prop="title" label="" width="80"> </el-table-column>
        <el-table-column
          v-for="(item, index) in list6Items"
          :render-header="(h, c) => renderHeader(h, c, item.title)"
          :key="index"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            {{ `${scope.row[item.key]}` }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-row flex-as">
      <div class="computed-title">支付趋势</div>
      <div class="flex-1 overflow-x">
        <el-table
          border
          class="m-b-10"
          :data="gameRecordTableData.list5"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          style="width: auto; flex: 0 1 auto"
        >
          <el-table-column prop="title" label="" width="80"> </el-table-column>
          <el-table-column
            v-for="(item, index) in list5Items1"
            :key="index"
            :label="item.label"
            :render-header="(h, c) => renderHeader(h, c, item.title)"
            :width="item.width"
          >
            <template slot-scope="scope">
              {{ `${scope.row[item.key]}` }}
            </template>
          </el-table-column>
        </el-table>
        <el-table
          border
          class="m-b-10"
          :data="gameRecordTableData.list5"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          style="width: auto; flex: 0 1 auto"
        >
          <el-table-column prop="title" label="" width="80"> </el-table-column>
          <el-table-column
            v-for="(item, index) in list5Items2"
            :key="index"
            :label="item.label"
            :render-header="(h, c) => renderHeader(h, c, item.title)"
            :width="item.width"
          >
            <template slot-scope="scope">
              {{ `${scope.row[item.key]}` }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="flex-row flex-as">
      <div class="computed-title flex-row flex-ac">
        性别占比
        <question-tooltip text="性别比例从2021年2月13日开始记录。" />
      </div>
      <el-table
        border
        class="m-b-10"
        :data="gameRecordTableData.list3"
        :header-cell-style="thStyle"
        :cell-style="cellStyle"
        style="width: auto; flex: 0 1 auto"
      >
        <el-table-column prop="title" label="" width="80"> </el-table-column>
        <el-table-column label="男性" width="130">
          <template slot-scope="scope">
            {{ scope.row.num1 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="女性" width="130">
          <template slot-scope="scope">
            {{ scope.row.num2 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-row flex-as">
      <div class="computed-title flex-row flex-ac">
        年龄占比
        <question-tooltip text="年龄比例从2021年2月13日开始记录。" />
      </div>
      <el-table
        border
        class="m-b-10"
        :data="gameRecordTableData.list4"
        :header-cell-style="thStyle"
        :cell-style="cellStyle"
        style="width: auto; flex: 0 1 auto"
      >
        <el-table-column prop="title" label="" width="80"> </el-table-column>
        <el-table-column label="≤17岁" width="130">
          <template slot-scope="scope">
            {{ scope.row.num1 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="18-24岁" width="130">
          <template slot-scope="scope">
            {{ scope.row.num2 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="25-29岁" width="130">
          <template slot-scope="scope">
            {{ scope.row.num3 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="30-39岁" width="130">
          <template slot-scope="scope">
            {{ scope.row.num4 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="≥40岁" width="130">
          <template slot-scope="scope">
            {{ scope.row.num5 }}{{ scope.row.unit }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import QuestionTooltip from "../../../../components/common/questionTooltip.vue";
export default {
  components: {
    QuestionTooltip,
  },
  props: {
    gameRecordTableData: {
      type: Object,
      deafult: () => {},
    },
  },
  created() {},
  data() {
    return {
      list1Items1: [
        {
          label: "总游戏用户数",
          title:
            "玩过游戏的用户数之和，同一用户当天多次访问不重复计。（例如一个用户玩过外购本和免费本内购的游戏各一次，不重复计，仅算一个总游戏用户数。）",
          key: "num1",
          unit: "人",
          width: "118",
        },
        {
          label: "免费本-游戏用户数",
          title:
            "当剧本免费，且没有任何内购收费项时，玩过剧本的用户数量，同一用户当天多次访问不重复计。",
          key: "num2",
          unit: "人",
          width: "120",
        },
        {
          label: "外购本-游戏用户数",
          title:
            "当剧本收费，且没有任何内购收费项时，玩过剧本的用户数量，同一用户当天多次访问不重复计。",
          key: "num3",
          unit: "人",
          width: "120",
        },
        {
          label: "总内购-游戏用户数",
          title:
            "当剧本有内购收费项时，玩过剧本的用户数量，同一用户当天多次访问不重复计。（是免费本内购和外购本内购游戏用户数之和，也是退出用户数①②③④⑤之和。）",
          key: "num4",
          unit: "人",
          width: "120",
        },
        {
          label: "免费本内购-游戏用户数",
          title:
            "当剧本免费，且有内购收费项时，玩过剧本的用户数量，同一用户当天多次访问不重复计。",
          key: "num5",
          unit: "人",
          width: "120",
        },
        {
          label: "外购本内购-游戏用户数",
          title:
            "当剧本收费，且有内购收费项时，玩过剧本的用户数量，同一用户当天多次访问不重复计。",
          key: "num6",
          unit: "人",
          width: "120",
        },
      ],
      list1Items2: [
        {
          label: "总游戏人次",
          title: "玩过游戏的人次之和，同一用户会重复计算。",
          key: "num7",
          unit: "人",
          width: "118",
        },
        {
          label: "免费本-游戏人次",
          title:
            "当剧本免费，且没有任何内购收费项时，玩过剧本的用户数量，同一用户重复计算。",
          key: "num8",
          unit: "人",
          width: "120",
        },
        {
          label: "外购本-游戏人次",
          title:
            "当剧本收费，且没有任何内购收费项时，玩过剧本的用户数量，同一用户重复计算。",
          key: "num9",
          unit: "人",
          width: "120",
        },
        {
          label: "总内购-游戏人次",
          title: "当剧本有内购收费项时，玩过剧本的用户数量，同一用户重复计算。",
          key: "num10",
          unit: "人",
          width: "120",
        },
        {
          label: "免费本内购-游戏人次",
          title:
            "当剧本免费，且有内购收费项时，玩过剧本的用户数量，同一用户重复计算。",
          key: "num11",
          unit: "人",
          width: "120",
        },
        {
          label: "外购本内购-游戏人次",
          title:
            "当剧本收费，且有内购收费项时，玩过剧本的用户数量，同一用户重复计算。",
          key: "num12",
          unit: "人",
          width: "120",
        },
      ],
      list1Items3: [
        {
          label: "1局用户数",
          key: "num13",
          unit: "人",
          width: "130",
        },
        {
          label: "2局用户数",
          key: "num14",
          unit: "人",
          width: "130",
        },
        {
          label: "≥3局用户数",
          key: "num15",
          unit: "人",
          width: "130",
        },
        {
          label: "无效用户数",
          title:
            "玩任意游戏，在5分钟内退出，并在后续24小时内没有再次访问小程序的新增用户，同一用户多次访问不重复计。",
          key: "num16",
          unit: "人",
          width: "130",
        },
      ],
      list2Items: [
        {
          label: "游戏局数",
          key: "num1",
          unit: "局",
          width: "130",
        },
        {
          label: "总中途退出用户数",
          title:
            "全部在“剧终投票”前退出的用户数量，同一用户当天多次访问不重复计。",
          key: "num2",
          unit: "人",
          width: "160",
        },
        {
          label: "语音耗时",
          key: "num3",
          unit: "分钟",
          width: "130",
        },
        {
          label: "游戏时长",
          key: "num4",
          unit: "分钟",
          width: "130",
        },
        // {
        //   label: "流失用户数",
        //   key: "num5",
        //   unit: "人",
        //   width: "130",
        // }
      ],
      list5Items1: [
        {
          label: "总销售额",
          title: "全部销售额，外购销售额、总内购销售额之和。",
          key: "num1",
          unit: "点券",
          width: "130",
        },
        {
          label: "外购销售额",
          title: "全部剧本商品的销售额。",
          key: "num2",
          unit: "点券",
          width: "130",
        },
        {
          label: "总内购销售额",
          title: "全部剧本内购商品的销售额，内容销售额、线索销售额之和。",
          key: "num3",
          unit: "点券",
          width: "130",
        },
        {
          label: "内容销售额",
          title: "剧本内购商品：剧本内容的销售额。",
          key: "num4",
          unit: "点券",
          width: "130",
        },
        {
          label: "线索销售额",
          title: "剧本内购商品：线索的销售额。",
          key: "num5",
          unit: "点券",
          width: "130",
        },
      ],
      list5Items2: [
        {
          label: "总客单价",
          title: "总销售额除以付费人次得出的每人每次平均消费额。",
          key: "num6",
          unit: "点券",
          width: "130",
        },
        {
          label: "外购客单价",
          title: "外购销售额除以剧本商品付费人次得出的每人每次平均消费额。",
          key: "num7",
          unit: "点券",
          width: "130",
        },
        {
          label: "总内购客单价",
          title: "总内购销售额除以内购商品付费人次得出的每人每次平均消费额。",
          key: "num8",
          unit: "点券",
          width: "130",
        },
        {
          label: "内容客单价",
          title: "内容销售额除以剧本内容付费人次得出的每人每次平均消费额。",
          key: "num9",
          unit: "点券",
          width: "130",
        },
        {
          label: "线索客单价",
          title: "线索销售额除以线索付费人次得出的每人每次平均消费额。",
          key: "num10",
          unit: "点券",
          width: "130",
        },
      ],
      list6Items: [
        {
          label: "总内购-游戏用户数",
          title:
            "当剧本有内购收费项时，玩过剧本的用户数量，同一用户当天多次访问不重复计。（是免费本内购和外购本内购游戏用户数之和，也是退出用户数①②③④⑤之和。）",
          key: "num1",
          unit: "人",
          width: "110",
        },
        {
          label: "退出用户数①",
          title:
            "当剧本有剧本内容收费项时，在未到达剧本内容商品的付费时机前，退出的用户，同一用户当天多次访问不重复计。",
          key: "num2",
          unit: "人",
          width: "110",
        },
        {
          label: "退出用户数②",
          title:
            "当剧本有剧本内容收费项时，在到达剧本内容商品的付费时机后，到达“剧终投票”前，且没有购买剧本内容，退出的用户，同一用户当天多次访问不重复计。",
          key: "num3",
          unit: "人",
          width: "123",
        },
        {
          label: "退出用户数③",
          title:
            "当剧本有剧本内容收费项时，到达“剧终投票”后，且没有购买剧本内容，退出的用户，同一用户当天多次访问不重复计。",
          key: "num4",
          unit: "人",
          width: "115",
        },
        {
          label: "退出用户数④",
          title:
            "当剧本有剧本内容收费项时，在到达剧本内容商品的付费时机后，到达“剧终投票”前，且购买了剧本内容，退出的用户，同一用户当天多次访问不重复计。",
          key: "num5",
          unit: "人",
          width: "120",
        },
        {
          label: "退出用户数⑤",
          title:
            "当剧本有剧本内容收费项时，到达“剧终投票”后，且购买了剧本内容，退出的用户，同一用户当天多次访问不重复计。",
          key: "num6",
          unit: "人",
          width: "120",
        },
      ],
    };
  },
  methods: {
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    renderHeader: function (h, { column }, title) {
      const arr = column.label.split("-");
      const res = arr.length > 1 ? [arr[0], h("br"), arr[1]] : [column.label];
      if (!title) return res;
      return [
        ...res,
        h(
          "el-tooltip",
          {
            props: {
              placement: "top",
              effect: "light",
              //content:"提示框内容",//如果提示内容短不需要换行，直接使用这个提示内容
            },
          },
          [
            h(
              "div",
              {
                //当前div是为了实现换行，就这换行研究了好久。。。
                slot: "content",
              },
              [title]
            ),
            h("span", {
              //问号模块
              class: {
                "el-icon-question": true,
                "m-l-5": true,
              },
            }),
          ]
        ),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>